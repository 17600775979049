import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { observable, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CrudService {

  constructor(private firestore: AngularFirestore) { }
  
  createNewGame(id:string,name:string,type:number) {
    return new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection('Games').doc(id)
            .set({picked:"Get Ready",player1:name,player2:null,type:type,status:0,player:1})
            .then(res => {}, err => reject(err));
    });
  }

  updateGame(id:string,field){
    return this.firestore.collection("Games").doc(id).set(field,{merge:true}).catch(err => {return null});
  }

  getGame(id:string) {
    return this.firestore.collection('Games').doc(id);
  }

  getQuestions(id:string) {
    return this.firestore.collection('Questions').doc(id);
  }



}