import { Component, OnInit} from '@angular/core';
import { CrudService } from '../crud.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.css']
})
export class PlayComponent implements OnInit{

  picked_question:string;
  current_game;
  questions;
  game_id: any;
  constructor(private crud:CrudService,private route : ActivatedRoute,public dataService:DataService) { }

  ngOnInit(){

    this.game_id=this.dataService.data.gameId;



    this.crud.getGame(this.game_id).get().toPromise().then(doc=>{
      if (doc.exists) {
        this.current_game=doc.data();
        if(this.current_game.type){
          $("body").css("background-image","url('assets/img/background11.jpg')")
        }
        this.crud.getQuestions(doc.data().type.toString()).get().toPromise().then(doc=>{
          if(doc.exists){
            this.questions=doc.data();

            this.crud.getGame(this.game_id).valueChanges().subscribe(data=>this.current_game=data);
          }else
            console.log("No such Questions");
        });

    } else {
        console.log("No such Game!");
    }
    });
    
    
  }
 
  
  showQuestion(){
      var que=this.questions.questions.split("?");
      var randomNumber = Math.floor(Math.random()*que.length);
      this.picked_question=que[randomNumber];
      this.crud.updateGame(this.game_id,{"player":((this.current_game.player+2)%2+1),"picked":this.picked_question});
    }
    
  }

  


