import { Injectable } from '@angular/core';
import {Data} from '../app/data';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  data:Data={gameId:"",player:0};
  constructor() { }

}
