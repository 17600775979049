<div class="container-fluid h-100 bf">
    <h2 class="row"><h1 class="mx-auto" style="margin-top: 4%;">Discover Your Partner</h1></h2>

    <div class="container animate__animated animate__backInUp" style="margin-top: 20vh;display: none;" #new>
        <div class="row">
            <div    class="col-lg-3"></div>
            <input  class="col-lg-2" style="font-size: 40%;" placeholder="Id For New Game"  [(ngModel)]="new_id" (focus)="new_error=false">
            <div    class="col-lg-1"></div>
            <input  class="col-lg-2" style="font-size: 40%;" placeholder ="Your name"      [(ngModel)]="name1" (focus)="new_error=false">
            <div    class="col-lg-4" style="font-family:arial;font-size: 40%;"><span class="btn btn-danger">
            <input  type="checkbox" [(ngModel)]="adult">+&nbsp;18</span></div>
        </div>
        <div class="row">
            
            <button class="col-lg-2 btn btn-warning text-center" style="font-size: 40%;margin:10%  0 0 38%" type="button" (click)="newGame()">Create New Game</button>
            
        </div>
    </div>

    <div class="container" #show>
        <div class="row" style="margin-top: 20vh;">
            <div class="col-lg-4"></div><button class="col-lg-4 btn btn-warning text-center" style="font-size: 60%;" type="button" (click)="showNewGame()">Create New Game</button><div class="col-lg-4"></div>
        </div>
        <div class="row" style="margin-top: 20vh;">
            <div class="col-lg-4"></div><button class="col-lg-4 btn btn-warning text-center" style="font-size: 60%;" type="button" (click)="showJoinGame()">Join Game</button><div class="col-lg-4"></div>
        </div>
    </div>

    <div class="row animate_animated animate__bounceIn" style="margin-top: 2vh;" *ngIf="new_error">
        <div    class="col-lg-2"></div>
        <div  class="col-lg-2 alert alert-danger" role="alert" style="font-size: 38%;" >{{new_alert}}</div>
        <div    class="col-lg-8"></div>
    </div>

    <div class="container animate__animated animate__backInUp" style="margin-top: 20vh;display: none;" #join>
        <div class="row">
            <div    class="col-lg-3"></div>
            <input  class="col-lg-2" style="font-size: 50%;" placeholder="Id For Game to join"  [(ngModel)]="join_id" (focus)="join_error=false">
            <div    class="col-lg-1"></div>
            <input  class="col-lg-2" style="font-size: 50%;" placeholder ="Your name"           [(ngModel)]="name2"   (focus)="join_error=false">
            <div    class="col-lg-4"></div> 
        </div>
        <div class="row">
            <button class="col-lg-2 btn btn-warning text-center" style="font-size: 40%;margin:10%  0 0 38%" type="button" (click)="joinGame()">Join Game</button>
            
        </div>
    </div>
    <div class="row animate_animated animate__bounceIn" style="margin-top: 2vh;" *ngIf="join_error">
        <div    class="col-lg-2"></div>
        <div    class="col-lg-2 alert alert-danger" role="alert" style="font-size: 38%;" >{{join_alert}}</div>
        <div    class="col-lg-8"></div>
    </div>
    
       
</div>