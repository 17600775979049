import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudService } from '../crud.service';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  new_id:string;
  join_error=false;
  join_alert:string;
  new_error=false;
  new_alert:string;
  join_id:string;
  name1:string;
  name2:string;
  adult:boolean=false;
  play_url="/play/"
  
  constructor(private crud:CrudService,private router:Router,private dataService:DataService) { }
  @ViewChild('show') show; 
  @ViewChild('new') new; 
  @ViewChild('join') join; 
  showNewGame(){
    this.show.nativeElement.style.display="none";
    this.new.nativeElement.style.display="";

  }
  showJoinGame(){
    this.show.nativeElement.style.display="none";
    this.join.nativeElement.style.display="";
  }
  
  
  
  newGame(){
    if(this.new_id && this.name1){
      this.crud.createNewGame(this.new_id,this.name1,this.adult?1:0);
      this.dataService.data.gameId=this.new_id;
      this.dataService.data.player=1;
      this.router.navigate([this.play_url]);
    }else{
      this.new_error=true;
      if(this.name1)
        this.new_alert="Please Enter Id For The New Game";
      else
        this.new_alert="Please Enter Your Name";
    
    }
  }

  joinGame(){
    this.crud.getGame(this.join_id).get().toPromise().then(data => {
      if(data.exists && this.name2){
        this.crud.updateGame(this.join_id,{player2:this.name2});
        this.dataService.data.gameId=this.join_id;
        this.dataService.data.player=2;
        this.router.navigate([this.play_url]);
      }else{
        this.join_error=true;
        if(this.name2)
          this.join_alert="Ther Is No Game With This Id";
        else
          this.join_alert="Please Enter Your Name";
      }
    })

  }


  ngOnInit(): void {

  }

}
