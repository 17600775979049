import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { PlayComponent } from './play/play.component';
import { CreateComponent } from './create/create.component';


const firebaseConfig = {
  apiKey: "AIzaSyANz9-o_awVD2KOxIgarJJAJ3EaHdMz_KE",
  authDomain: "questions-game-f0e8a.firebaseapp.com",
  databaseURL: "https://questions-game-f0e8a.firebaseio.com",
  projectId: "questions-game-f0e8a",
  storageBucket: "questions-game-f0e8a.appspot.com",
  messagingSenderId: "143706918323",
  appId: "1:143706918323:web:29d502ed912ee332190fc6"
};

@NgModule({
  declarations: [
    AppComponent,
    PlayComponent,
    CreateComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
