<div class="container-fluid h-100 "  >
    <div class="row text-center"> 
      <div class="col-4 bf" style=" margin:auto;margin-top: 40px; font-size:30px">
        <p >{{current_game.player1 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{current_game.player2?current_game.player2:'player 2 ...'}}</p>
      </div>
    </div>
    <div class="row h-50">
      <div class="text-center col-sm-12 my-auto bf">{{current_game.picked}}</div>
    </div>
    <div class="row justify-content-center">
      <button type="button" class="btn btn-warning text-center col-5 my-auto bf" style="font-size: 20px;" (click)="showQuestion()" [disabled]="!(current_game.player2 && dataService.data.player==current_game.player)">Next Question</button>
    </div>
  </div>
